import React, { useEffect, useRef, useState } from 'react';
import ImageEditor from '@toast-ui/react-image-editor';
import 'tui-image-editor/dist/tui-image-editor.css';
import SideBar from '../../layouts/SideBar';
import useFetch from '../../ApiClient/GetApi';
import { IMAGE_URL } from '../../Utils/urls';
import { PostApi } from '../../ApiClient/PostApi';
import { success_toaster, error_toaster } from '../../Utils/Toaster';
import { useNavigate } from 'react-router-dom';
import { userHomeRoute } from '../../Utils/routes';
import Loader from '../../Utils/Loader';

export default function AdvancedImageEditor() {
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false)
  const [caseId, setCaseId] = useState(null);
  const [images, setImages] = useState([]); // Store images data
  const [selectedImage, setSelectedImage] = useState(''); // Store selected image URL
  const imageEditorRef = useRef(null); // Ref for Toast UI Image Editor instance

  // Fetch user ID from localStorage
  useEffect(() => {
    const id = localStorage.getItem('userId');
    setUserId(id);
  }, []);

  // Fetch all images using custom hook
  const { apiData } = useFetch(`/user/getAllCases/${userId}`);

  // Handle image selection from dropdown
  const handleImageSelection = async (e) => {
    const selectedImagePath = e.target.value;
    if (selectedImagePath) {
      const imageUrl = `${IMAGE_URL}${selectedImagePath}`;
      setSelectedImage(imageUrl);

      if (imageEditorRef.current) {
        try {
          await imageEditorRef.current
            .getInstance()
            .loadImageFromURL(imageUrl, 'Selected Image');
          console.log('Image loaded successfully:', imageUrl);
        } catch (error) {
          console.error('Error loading image into editor:', error);
        }
      }
    }
  };

  // Fetch images for selected case
  const handleChangeCase = async (id) => {
    setCaseId(id)
    let response = await PostApi('/user/caseImages', { id: id });
    setImages(response?.data?.data?.images || []);
  };

  // Function to save the edited image
  const handleSaveImage = async () => {

    if (imageEditorRef.current) {
      try {
        // Get edited image as Blob
        const editorInstance = imageEditorRef.current.getInstance();
        const editedImageDataURL = editorInstance.toDataURL(); // Get base64 image
        const blob = await fetch(editedImageDataURL).then((res) => res.blob());
        setLoading(true)
        // Create FormData and append image
        const formData = new FormData();
        formData.append('image', blob, 'edited-image.png');
        formData.append('userId', userId);
        formData.append('caseId', caseId);

        // Send the image to the API
        const response = await PostApi('/user/saveImage', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });


        if (response?.data?.status === '1') {
          setLoading(false)
          navigate(userHomeRoute)
          success_toaster('Image saved successfully!');
        } else {
          setLoading(false)
          error_toaster('Failed to save the image.');
        }
      } catch (error) {
        console.error('Error saving the image:', error);
        alert('An error occurred while saving the image.');
      }
    }
  };

  return (
    <div>
      {
        loading ? <Loader /> : <div className="main-wrapper">
          <SideBar />
          <div className="page-wrapper">
            <div className="content">
              <div className="page-header">
                <div className="page-title">
                  <h4>Advanced Image Editor</h4>
                  <h6>Edit your images with advanced tools</h6>
                </div>
              </div>

              {/* Dropdown to select image */}
              <div className="d-flex gap-3">
                <div className="col-md-4 mb-3" style={{ textAlign: 'start' }}>
                  <label htmlFor="caseDropdown" className="form-label">
                    Select a Case:
                  </label>
                  <select
                    id="caseDropdown"
                    className="form-select"
                    onChange={(e) => handleChangeCase(e.target.value)}
                  >
                    <option value="">-- Select Case --</option>
                    {apiData?.data?.cases.map((cc) => (
                      <option key={cc.id} value={cc.id}>
                        {cc.caseNo}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-4 mb-3" style={{ textAlign: 'start' }}>
                  <label htmlFor="imageDropdown" className="form-label">
                    Select an Image to Edit:
                  </label>
                  <select
                    id="imageDropdown"
                    className="form-select"
                    onChange={(e) => handleImageSelection(e)}
                  >
                    <option value="">-- Select Image --</option>
                    {images.map((img) => (
                      <option key={img.id} value={img.image}>
                        {img.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4 mb-3" style={{ textAlign: 'start' }}>
                  <label htmlFor="imageDropdown" className="form-label">
                    Save edited Image
                  </label>
                  <div>
                    <button className="btn btn-primary" onClick={handleSaveImage}>
                      Save Image
                    </button>
                  </div>
                </div>
              </div>

              {/* Toast UI Image Editor */}
              <div className="editor-container" style={{ height: '500px' }}>
                <ImageEditor
                  ref={imageEditorRef}
                  includeUI={{
                    loadImage: {
                      path: '/assets/img/coming-soon.png', // Default placeholder image
                      name: 'Default Image',
                    },
                    menu: ['crop', 'draw', 'text', 'rotate', 'shape', 'icon', 'filter'],
                    initMenu: 'filter',
                    uiSize: { width: '100%', height: '700px' },
                    menuBarPosition: 'bottom',
                  }}
                  cssMaxHeight={500}
                  cssMaxWidth={700}
                  selectionStyle={{ cornerSize: 20, rotatingPointOffset: 70 }}
                  usageStatistics={true}
                />
              </div>

              {/* Save Button */}

            </div>
          </div>
        </div>
      }
    </div>
  );
}
