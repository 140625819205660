import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { LoginCheck } from "./AuthCheck";

export default function AdminProtectedRoutes(props) {
  const [isAuthorized, setIsAuthorized] = useState(true);

  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role !== "admin") {
      setIsAuthorized(false); // Unauthorized, set state
    }
  }, []);

  LoginCheck(); // Assuming this function has side effects and doesn’t affect rendering directly

  if (!isAuthorized) {
    return <p>You don't have permission to access this page!</p>;
  }

  const { Component } = props;
  return (
    <>
      <Component />
    </>
  );
}
