
import { ToastContainer } from 'react-toastify';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Login from './components/Login';
import { createCasesRoute, editCaseRoute, editor, homeRoute, paymentSuccessRoute, plansRoute, purchasePlan, registerRoute, signinRoute, userCaseDetails, userHomeRoute, usersRoutes } from './Utils/routes';
import Home from './components/Home';
import Plan from './components/Plan';
import Users from './components/Users';
import AdminProtectedRoutes from './Utils/AdminProtectedRoutes';
import UserProtectedRoutes from './Utils/UserProtectedRoutes';

import AllCases from "./components/UserComponents/AllCases"
import Case from "./components/UserComponents/Case"
import PurchasePlan from "./components/UserComponents/PurchasePlan"
import PaymentSuccess from "./components/UserComponents/PaymentSuccess"
import CaseDetails from "./components/UserComponents/CaseDetails"
import AdvancedImageEditor from './components/UserComponents/Editor';
import Register from './components/UserComponents/Register';
import EditCase from './components/UserComponents/EditCase';

// import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
    <ToastContainer />
    <Router basename="/">
     <div>
       {/* Route Definitions */}
       <Routes>
       
         <Route path={signinRoute} element={<Login />} />
         <Route path={registerRoute} element={<Register />} />

         {/* ADMIN ROUTES */}
         <Route path={homeRoute} element={<AdminProtectedRoutes Component={Home} />} />
         <Route path={plansRoute} element={<AdminProtectedRoutes Component={Plan} />} />
         <Route path={usersRoutes} element={<AdminProtectedRoutes Component={Users} />} />

         {/* USER ROUTES */}

         <Route path={userHomeRoute} element={<UserProtectedRoutes Component={AllCases} />} />
         <Route path={createCasesRoute} element={<UserProtectedRoutes Component={Case} />} />
         <Route path={purchasePlan} element={<UserProtectedRoutes Component={PurchasePlan} />} />
         <Route path={paymentSuccessRoute} element={<PaymentSuccess />} />
         <Route path={userCaseDetails} element={<UserProtectedRoutes Component={CaseDetails} />} />
         <Route path={editor} element={<UserProtectedRoutes Component={AdvancedImageEditor} />} />
         <Route path={editCaseRoute} element={<UserProtectedRoutes Component={EditCase} />} />
        
        
       </Routes>
     </div>
   </Router>
 </div>
  );
}

export default App;
