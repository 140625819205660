import React from 'react';
import useFetch from '../../ApiClient/GetApi';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; // Slick styles
import 'slick-carousel/slick/slick-theme.css'; // Slick theme styles
import Loader from '../../Utils/Loader';
import "./purchase.css"
import { PostApi } from '../../ApiClient/PostApi';
import { getAllPlans, getAllSubscriptionPlans, subscribePlanRoute } from '../../Utils/routes';
import { error_toaster } from '../../Utils/Toaster';

export default function PurchasePlan() {
    const { apiData, loading, error } = useFetch(getAllPlans); // Replace with your actual API URL
    console.log(apiData?.data);

    if (loading) return <div><Loader /></div>;
    const subscribePlan = async (priceId) => {
        let response = await PostApi(subscribePlanRoute,{priceId,userId:localStorage.getItem("userId")});
        console.log(response);
        if(response.data.status == "1"){
            localStorage.setItem("priceId",priceId);
             window.location.href = response?.data?.data?.url;
        }
        else{
            error_toaster(response.data.message)
        }
    }
    

    // Slick settings for the slider
    const settings = {
        dots: true, // Show navigation dots
        infinite: true, // Infinite scrolling
        speed: 500, // Transition speed in milliseconds
        slidesToShow: 3, // Number of visible slides
        slidesToScroll: 1, // Number of slides to scroll at once
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2, // For medium screens (tablets)
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1, // For small screens (mobile)
                },
            },
        ],
    };
    const staticCarouselSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    const cardStyle = {
        padding: '20px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

    };

    const imageStyle = {
        width: '100%',
        height: 'auto',
        borderRadius: '8px',
        marginBottom: '15px',
    };
    return (
        <div className="plan-slider-container" style={{ padding: '20px' }}>
            <h2 className='text-center'>Purchase Plan</h2>
            <Slider {...settings}>
                {apiData && apiData.data && apiData.data.length > 0 ? (
                    apiData.data.map((plan) => (
                        <div className="card" style={{ width: '18rem', height: '350px', backgroundColor: '#e7f9e7', padding: '20px' }}>
                            <div className="card-body d-flex flex-column justify-content-between" style={{ height: '100%' }}>
                                <h5 className="card-title">{plan.productName}</h5>

                                <p><strong>Price:</strong> ${plan.unitAmount}/{plan.recurringInterval}</p>
                                <p><strong>Interval:</strong> {plan.recurringInterval.toUpperCase()}</p>
                                <button onClick={() => subscribePlan(plan.priceId)} className="btn btn-primary">Purchase</button>
                            </div>
                        </div>
                    ))
                ) : (
                    <div>No plans available.</div>
                )}
            </Slider>



        </div>
    );
}
