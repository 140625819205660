import React, { useRef, useState } from 'react';
import { success_toaster, error_toaster } from '../../Utils/Toaster';
import Loader from "../../Utils/Loader";
import { useNavigate } from 'react-router-dom';
import { allCasesRoute, userHomeRoute } from '../../Utils/routes';
import SideBar from '../../layouts/SideBar';
import { PostApi } from '../../ApiClient/PostApi';
import PdfToImg from "pdftoimg-js/browser";


export default function Case() {
    const navigate = useNavigate();
    const inputRefs = useRef({});
    
    const [images, setImages] = useState([{ id: Date.now(), file: null, name: '' }]); // Manage dynamic image uploads with name
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        caseNo: '',
        clientName: '',
        phone: '',
        email: '',
        detail: '',
    });

     // Convert PDF to Image
     const convertPdfToImage = async (file, id) => {
        try {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const pdfArrayBuffer = e.target.result; // Get the ArrayBuffer of the file
                const images = await PdfToImg(pdfArrayBuffer); // Convert PDF to base64 images
    
                if (images && images.length > 0) {
                    // Process each page or the first page as needed
                    const base64Image = images[0]; // Example: Take the first page
                    const blob = await fetch(base64Image).then((res) => res.blob());
    
                    setImages((prevImages) =>
                        prevImages.map((image) =>
                            image.id === id ? { ...image, file: blob, name: '' } : image
                        )
                    );
                    // success_toaster("PDF converted to image successfully!");
                } else {
                    error_toaster("No pages found in the PDF.");
                }
            };
            reader.readAsArrayBuffer(file); // Read file as ArrayBuffer
        } catch (error) {
            console.error("Error converting PDF to image:", error);
            error_toaster("Failed to convert PDF to image.");
        }
    };


    // Handle file changes (PDF or images)
    const handleImageChange = async (id, file) => {
        if (file) {
          if (file.type === "application/pdf") {
            await convertPdfToImage(file, id);
          } else {
            setImages((prevImages) =>
              prevImages.map((image) =>
                image.id === id ? { ...image, file, name: file.name } : image
              )
            );
          }
        }
      };
    const handleImageNameChange = (id, name) => {
        setImages((prevImages) =>
            prevImages.map((image) =>
                image.id === id ? { ...image, name } : image
            )
        );
    };
    // Add new input field
    const handleAddImage = () => {
        setImages([...images, { id: Date.now(), file: null, name: '' }]);
    };

    // Remove an input field
    const handleRemoveImage = (id) => {
        setImages(images.filter((image) => image.id !== id));
    };

    // Handle form changes
    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.caseNo || !formData.clientName || !formData.phone || !formData.email) {
            error_toaster('Please fill all required fields.');
            return;
        }

        const payload = new FormData();
        payload.append('caseNo', formData.caseNo);
        payload.append('clientName', formData.clientName);
        payload.append('phone', formData.phone);
        payload.append('email', formData.email);
        payload.append('detail', formData.detail);
        payload.append('userId', localStorage.getItem('userId'));

        images.forEach((image) => {
            if (image.file) {
                payload.append('images', image.file);
                payload.append('imageNames', image.name || `Image-${image.id}`);
            }
        });

        try {
            setLoading(true);
            const response = await PostApi('/user/addCase', payload, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            if (response.data.status === '1') {
                success_toaster(response.data.message);
                setLoading(false);
                navigate(userHomeRoute);
            } else {
                setLoading(false);
                error_toaster(response.data.message || 'Failed to add case.');
            }
        } catch (error) {
            setLoading(false);
            console.error('Error adding case:', error.response || error);
            error_toaster(error.message);
        }
    };



    return (
        <div >
            {
                loading ? <Loader /> : <div>
                    <SideBar />
                    <div className="page-wrapper">
                        <div className="content">
                            <div className="row">
                                <div className="page-header">
                                    <div className="page-title">
                                        <h4>Add Cases</h4>
                                        <h6>Create all Cases here</h6>
                                    </div>
                                    <div className="page-btn">
                                        <a href="case.html" className="btn btn-added color">Back to Case</a>
                                    </div>
                                </div>
                                <form className="mb-5 mt-5" onSubmit={handleSubmit}>
                                    <div className="row mb-4">
                                        <div className="col-md-6">
                                            <label htmlFor="caseNo" className="form-label">Case No</label>
                                            <input
                                                type="text"
                                                min="1"
                                                className="form-control"
                                                id="caseNo"
                                                value={formData.caseNo}
                                                onChange={handleInputChange}
                                                placeholder="Enter Case Number"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="clientName" className="form-label">Client Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="clientName"
                                                value={formData.clientName}
                                                onChange={handleInputChange}
                                                placeholder="Enter Client Name"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <div className="col-md-6">
                                            <label htmlFor="phone" className="form-label">Phone No</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="phone"
                                                value={formData.phone}
                                                onChange={handleInputChange}
                                                placeholder="Enter Phone No"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                placeholder="smith@gmail.com"
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <div className="col-md-12">
                                            <label htmlFor="detail" className="form-label">Case Detail</label>
                                            <textarea
                                                className="form-control"
                                                id="detail"
                                                rows="4"
                                                value={formData.detail}
                                                onChange={handleInputChange}
                                                placeholder="Enter Client Detail here..."
                                            ></textarea>
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <div className="col-lg-12" style={{ textAlign: "start" }}>
                                            <h6 style={{ marginBottom: "8px" }}>Upload Documents</h6>
                                            {images.map((image, index) => (
                                                <div key={image.id} className="add-choosen mb-2">
                                                    <div className="d-flex align-items-center">
                                                        <input
                                                            type="file"
                                                            accept="image/png, image/jpeg, application/pdf"
                                                            ref={(el) => (inputRefs.current[image.id] = el)} // Store dynamic ref
                                                            onChange={(e) => handleImageChange(image.id, e.target.files[0], inputRefs.current[image.id])}
                                                            className="form-control"
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control me-2"
                                                            value={image.name}
                                                            onChange={(e) => handleImageNameChange(image.id, e.target.value)}
                                                            placeholder="Document Name"
                                                        />
                                                        {index > 0 && (
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger"
                                                                onClick={() => handleRemoveImage(image.id)}
                                                            >
                                                                Remove
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                            <button type="button" className="btn btn-primary mt-2" onClick={handleAddImage}>
                                                Add More
                                            </button>
                                        </div>
                                       
                                    </div>

                                    <div className="mt-5">
                                        <button type="submit" className="btn btn-primary me-3">Save</button>
                                        <button type="button" className="btn btn-secondary">Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}
