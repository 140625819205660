import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { PostApi } from '../../ApiClient/PostApi';
import { success_toaster, error_toaster } from '../../Utils/Toaster';
import { confirmSubscriptionRoute, homeRoute, userHomeRoute } from '../../Utils/routes';

export default function PaymentSuccess() {


  const [isApiCalled, setIsApiCalled] = useState(false); // State flag to track API call
  const [loading, setLoading] = useState(true); // Loading state for API call
  const [priceId, setPriceId] = useState(null)
  useEffect(() => {
    setPriceId(localStorage.getItem('priceId'));
  }, []);
  console.log(localStorage.getItem("priceId"));
  useEffect(() => {

    // Check if planId is available and isApiCalled is false
    if (!isApiCalled) {
      const confirmSubscription = async () => {
        try {
          const userId = localStorage.getItem('userId');
          if (!userId) {
            error_toaster('User is not logged in.');
            return;
          }
          // Call the API to confirm subscription
          const response = await PostApi(confirmSubscriptionRoute, { priceId, userId });
          // Log the full response to check its content
          console.log('API Response:', response);

          if (response.data.status === '1') {
            localStorage.setItem("isPlan","1");
            success_toaster(response.data.message || 'Subscription confirmed successfully!');
          } else {
            error_toaster(response.data.message || 'Failed to confirm the subscription.');
          }
        } catch (error) {
          console.error('Error confirming subscription:', error);
          // error_toaster('An error occurred while confirming your subscription.');
        } finally {
          setIsApiCalled(true); // Set the flag to prevent duplicate calls
          setLoading(false); // Stop loading once the API call is finished
        }
      };

      confirmSubscription();
    } else if (!priceId) {
      error_toaster('No planId found in the URL.');
      setLoading(false); // Stop loading if there's no planId
    }
  }, [priceId, isApiCalled]);

  if (loading) {
    // Optionally, show a loading indicator while the API is being called
    return (
      <div style={styles.container}>
        <h2>Loading...</h2>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <img
        src="https://th.bing.com/th/id/R.0de41a3c5953fba1755ebd416ec109dd?rik=QHpqFFk%2fVmVmPA&pid=ImgRaw&r=0"
        alt="Payment Success"
        style={styles.gif}
      />
      <h1 style={styles.message}>Payment Successful!</h1>
      <p style={styles.description}>
        Thank you for your payment! Your subscription has been successfully activated.
      </p>
      <button className="btn btn-success">
        <Link className="text-white" to={userHomeRoute}>Go to Home</Link>
      </button>
    </div>
  );
}

const styles = {
  container: {
    textAlign: 'center',
    padding: '50px',
    backgroundColor: '#f8f9fa',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gif: {
    width: '200px',
    marginBottom: '20px',
  },
  message: {
    color: '#28a745',
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  description: {
    fontSize: '1.2rem',
    color: '#6c757d',
  },
};
