import React, { useEffect, useState } from 'react';
import SideBar from '../layouts/SideBar';
import DataTable from 'react-data-table-component';
import customStyles from '../Utils/CustomStyles';
import useFetch from "../ApiClient/GetApi";
import { getAllUsers } from '../Utils/routes'; // Assuming getAllUsers fetches this data
import { FaEdit } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { formatDate } from '../Utils/DateFormat';
import { Modal, Button, Form } from 'react-bootstrap'; // Use react-bootstrap for modal and form components
import { PostApi } from '../ApiClient/PostApi';
import { error_toaster, success_toaster } from '../Utils/Toaster';
import Loader from '../Utils/Loader';

export default function Users() {
  // Fetch the API data
  const { apiData, reFetch } = useFetch(getAllUsers);
  const [loading, setLoading] = useState(false);

  // State for formatted users
  const [users, setUsers] = useState([]);

  // Modal state
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null); // User selected for editing
  const [formData, setFormData] = useState({ name: '', email: '', password: '' });

  // Format API data into the structure needed for the table
  useEffect(() => {
    if (apiData && apiData.data && apiData.data.users) {
      const formattedUsers = apiData.data.users.map((user, index) => ({
        srNo: index + 1, // Serial number for each user
        id: user.id,
        name: user.name,
        email: user.email,
        status: user.status ? 'Active' : 'Inactive', // Display active/inactive status
        createdAt: formatDate(user.createdAt), // Format the date
        action: (
          <div className="edit-delete-action d-flex gap-2">
            <div
              className="cursor-pointer"
              onClick={() => handleEditClick(user)}
            >
              <FaEdit size={25} />
            </div>
            {/* <div>
              <RiDeleteBin6Line size={25} />
            </div> */}
          </div>
        ), // Add a button or other action as needed
      }));
      setUsers(formattedUsers);
    }
  }, [apiData]);

  // Open modal for editing
  const handleEditClick = (user) => {
    setSelectedUser(user);
    setFormData({ name: user.name, email: user.email, password: '' }); // Pre-fill the form
    setShowModal(true);
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Submit form to update user
  const handleFormSubmit = async () => {
    // Add API logic to update the user
    console.log('Updated user data:', { id: selectedUser.id, ...formData });
    setLoading(true);
    let response = await PostApi("/admin/updateUser", { id: selectedUser.id, ...formData });
    if (response.data.status == "1") {
      reFetch();
      setLoading(false);
      success_toaster(response.data.message);
      setShowModal(false);
    }
    else {
      setLoading(false);
      error_toaster(response.data.message)
    }

    // Close modal after successful update
    setShowModal(false);
  };

  // Define the columns for DataTable
  const columns = [
    {
      name: "User ID",
      selector: (row) => row.id,
      wrap: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      wrap: true,
    },
    {
      name: "Created At",
      selector: (row) => row.createdAt,
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      wrap: true,
    },
  ];

  return (
    <div>
      {
        loading ? <Loader /> : <div>
          <SideBar />
          <div className="page-wrapper">
            <div className="content">
              <div className="row">
                <div className="page-header">
                  <div className="page-title">
                    <h4>All Users</h4>
                    <h6>View all users here</h6>
                  </div>
                </div>

                <div className="card table-list-card">
                  <div className="card-body">
                    <div className="table-responsive product-list">
                      <DataTable
                        theme="myTheme"
                        customStyles={customStyles}
                        columns={columns}
                        data={users} // Populate with formatted users data
                        pagination
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal for editing user */}
          <Modal show={showModal} onHide={() => setShowModal(false)} centered>
            <Modal.Header closeButton>
              <Modal.Title>Edit User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formName" className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group controlId="formEmail" className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group controlId="formPassword" className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter new password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleFormSubmit}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      }
    </div>
  );
}
