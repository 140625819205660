import React, { useEffect, useState } from 'react';
import SideBar from '../layouts/SideBar';
import DataTable from 'react-data-table-component';
import customStyles from '../Utils/CustomStyles';
import useFetch from "../ApiClient/GetApi";
import { PostApi } from "../ApiClient/PostApi";
import { getAllSubscriptionPlans, addPlan, deletePlanRoute } from '../Utils/routes';
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Modal, Button, Form } from "react-bootstrap";
import { success_toaster, error_toaster } from "../Utils/Toaster";
import Loader from "../Utils/Loader";
import { error } from 'pdf-lib';

export default function Plan() {
    const { apiData, reFetch } = useFetch(getAllSubscriptionPlans);

    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    // State for handling Add/Edit Mode
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentPlanId, setCurrentPlanId] = useState(null);

    // Form State
    const [planData, setPlanData] = useState({
        name: '',
        interval: 'month',
        price: '',
        productId: ''
    });

    // Format API data for table
    useEffect(() => {
        if (apiData?.data?.plans) {
            const formattedPlans = apiData.data.plans.map((plan, index) => ({
                srNo: index + 1,
                priceId: plan.id,
                productId: plan.productId,
                productName: plan.productName,
                amount: (plan.amount / 100).toFixed(2),
                currency: plan.currency.toUpperCase(),
                interval: plan.interval,
                productDescription: plan.productDescription,
                action: (
                    <div className="edit-delete-action d-flex gap-2">
                        <div className="cursor-pointer" onClick={() => handleEdit(plan)}>
                            <FaEdit size={25} />
                        </div>
                        <div>
                            <RiDeleteBin6Line onClick={() => deletePlan(plan.productId, plan.id)} size={25} />
                        </div>
                    </div>
                ),
            }));
            setPlans(formattedPlans);
        }
    }, [apiData]);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => {
        setShowModal(false);
        setIsEditMode(false);
        setCurrentPlanId(null);
        resetForm();
    };

    const resetForm = () => {
        setPlanData({ name: '', interval: 'month', price: '' });
    };

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPlanData((prevData) => ({ ...prevData, [name]: value }));
    };

    // Add or Update Plan
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const payload = {
                ...planData,
                planId: currentPlanId, // Send planId if editing
            };

            const endpoint = isEditMode ? `${addPlan}/update` : addPlan; // Determine API route
            const response = await PostApi(endpoint, payload);

            if (response.data.status === '1') {
                reFetch();
                success_toaster(response.data.message);
                handleCloseModal();
            } else {
                error_toaster(response.data.message);
            }
        } catch (error) {
            error_toaster("Something went wrong");
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    const deletePlan = async (productId, priceId) => {
        setLoading(true);
        try {
            const response = await PostApi(deletePlanRoute, { productId, priceId });

            if (response.data.status === '1') {
                reFetch();
                success_toaster(response.data.message);
            } else {
                error_toaster(response.data.message);
            }
        } catch (error) {
            error_toaster(error.message);
        } finally {
            setLoading(false);
        }
    };
    // Open modal with plan data for editing


    const handleEdit = (plan) => {
        setIsEditMode(true);
        setCurrentPlanId(plan.id);
        setPlanData({
            name: plan.productName,
            interval: plan.interval,
            price: plan.amount,
            productId: plan.productId
        });
        handleShowModal();
    };

    const updatePlan = async () => {
        setLoading(true)
        let response = await PostApi("/admin/updatePlan", {
            priceId: currentPlanId,
            planData: planData

        });
        if (response.data.status == "1") {
            reFetch();
            setLoading(false)
            success_toaster(response.data.message)
        }
        else {
            setLoading(false);
            error_toaster(response.data.message)
        }
        console.log(response)
    }

    // Table Columns
    const columns = [
        { name: "Sr #", selector: (row) => row.srNo, width: "100px" },
        { name: "Product Name", selector: (row) => row.productName, width: "200px" },
        { name: "Amount", selector: (row) => `${row.currency} ${row.amount}` },
        { name: "Interval", selector: (row) => row.interval },
        { name: "Action", selector: (row) => row.action },
    ];

    return (
        <div>
            <SideBar />
            {loading ? <Loader /> : (
                <div>
                    <div className="page-wrapper">
                        <div className="content">
                            <div className="row">
                                <div className="page-header">
                                    <div className="page-title">
                                        <h2>All Plans</h2>
                                        <h6>View all plans here</h6>
                                    </div>
                                    <div className="page-btn">
                                        <Button variant="primary" onClick={handleShowModal}>
                                            New Plan
                                        </Button>
                                    </div>
                                </div>

                                <div className="card table-list-card">
                                    <div className="card-body">
                                        <DataTable
                                            customStyles={customStyles}
                                            columns={columns}
                                            data={plans}
                                            pagination
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Modal for Add/Edit Plan */}
                    <Modal show={showModal} onHide={handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>{isEditMode ? 'Edit Plan' : 'Add New Plan'}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form >

                                <Form.Group controlId="planName">
                                    <Form.Label>Plan Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter plan name"
                                        name="name"
                                        value={planData.name}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="planInterval">
                                    <Form.Label>Interval</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="interval"
                                        value={planData.interval}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="month">Month</option>
                                        <option value="week">Week</option>
                                        <option value="year">Year</option>
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group controlId="planPrice">
                                    <Form.Label>Price</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter price"
                                        name="price"
                                        value={planData.price}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>

                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseModal}>
                                        Close
                                    </Button>
                                    {
                                        isEditMode ? <Button variant="primary" onClick={updatePlan}>
                                            Update Plan
                                        </Button> : <Button variant="primary" onClick={handleSubmit}>
                                            Add Plan
                                        </Button>
                                    }

                                </Modal.Footer>
                            </Form>
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </div>
    );
}
