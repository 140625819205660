import React, { useEffect, useState } from 'react';
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button from react-bootstrap
import DataTable from 'react-data-table-component'; // Import DataTable
import customStyles from '../../Utils/CustomStyles';
import useFetch from '../../ApiClient/GetApi';
import SideBar from '../../layouts/SideBar';
import { IMAGE_URL } from '../../Utils/urls';
import { deleteCase, editCaseRoute, userCaseDetails, usergetAllCases } from '../../Utils/routes';
import { useNavigate } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';
import Loader from "../../Utils/Loader";
import { PostApi } from '../../ApiClient/PostApi';
import { error_toaster, success_toaster } from '../../Utils/Toaster';

export default function AllCases() {
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);
  const [currentImages, setCurrentImages] = useState([]); 
  const [modalShow, setModalShow] = useState(false); 
  const [cases, setCases] = useState([]); 
  const [loading, setLoading] = useState(true); 

  // Set userId from localStorage on component mount
  useEffect(() => {
    const id = localStorage.getItem('userId');
    setUserId(id);
  }, []); // Empty dependency array to run this effect only once

  console.log(`user id = > ${userId}`)

  // Only fetch cases if userId is not null
  const { apiData, reFetch } = useFetch(userId ? `/user/getAllCases/${userId}` : null);

  // Sync fetched API data with local state after fetch
  useEffect(() => {
    if (apiData?.data?.cases) {
      setCases(apiData.data.cases); 
      setLoading(false); 
    } else if (apiData?.data?.cases === null) {
      setCases([]); 
      setLoading(false); 
    }
  }, [apiData]);

  // Close the modal
  const handleCloseModal = () => {
    setModalShow(false);
    setCurrentImages([]); 
  };

  // Handle delete case
  const handleDeleteCase = async (caseId) => {
    try {
      let response = await PostApi(deleteCase, { caseId });
      if (response.data.status === "1") {
        reFetch();
        setLoading(false);
        success_toaster(response.data.message);
      } else {
        error_toaster(response.data.message);
      }
    } catch (error) {
      console.error("Error deleting case:", error);
    }
  };

  // Navigate to case details page
  const CaseDetails = (id) => {
    navigate(userCaseDetails, { state: { id } });
  };

  const editCase = (id) => {
    navigate(editCaseRoute, { state: { id } });
  };

  // Function to handle image download and API call
  const handleDownloadImage = async (imageName) => {
    try {
      // API call to download image (use the appropriate route or API endpoint)
      const response = await PostApi('/user/downloadImage', { imageName });

      if (response.data.status === "1") {
        const imageUrl = response.data.imageUrl; // Assuming API returns an image URL
        console.log('Downloaded Image URL: ', imageUrl);

        // Create an anchor tag to simulate downloading the image
        const link = document.createElement('a');
        link.href = imageUrl; // Set the download URL
        link.download = imageName; // Set the download file name
        link.click(); // Trigger the download
      } else {
        error_toaster(response.data.message);
      }
    } catch (error) {
      console.error("Error downloading image:", error);
      error_toaster("Failed to download image.");
    }
  };

  // Define columns for DataTable
  const columns = [
    {
      name: "Case No",
      selector: (row) => row.caseNo,
      wrap: true,
      width: "200px",
    },
    {
      name: "Client Name",
      selector: (row) => row.clientName,
      wrap: true,
      width: "200px",
    },
    {
      name: "Phone No",
      selector: (row) => row.phoneNum,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="edit-delete-action d-flex gap-2">
          <div style={{ cursor: "pointer" }}>
            <FaEye onClick={() => CaseDetails(row.id)} size={20} />
          </div>
          <div style={{ cursor: "pointer" }}>
            <BiEdit onClick={() => editCase(row.id)} size={20} />
          </div>
          <div style={{ cursor: "pointer" }}>
            <RiDeleteBin6Line onClick={() => handleDeleteCase(row.id)} size={20} />
          </div>
        </div>
      ),
      wrap: true,
    },
  ];

  return (
    <div>
      {loading ? (
        <Loader /> // Display loading spinner while data is being fetched
      ) : (
        <div className="main-wrapper">
          <SideBar />
          <div className="page-wrapper">
            <div className="content">
              <div className="row">
                <div className="page-header">
                  <div className="page-title">
                    <h4 style={{ textAlign: "start" }}>All Cases</h4>
                    <h6>View all Cases here</h6>
                  </div>
                </div>

                <div className="card table-list-card">
                  <div className="card-body">
                    <div className="table-responsive product-list">
                      <DataTable
                        theme="myTheme"
                        customStyles={customStyles}
                        columns={columns}
                        data={cases} // Display the fetched cases data
                        pagination
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Bootstrap Modal for displaying images */}
          <Modal
            show={modalShow}
            onHide={handleCloseModal}
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Case Images</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {currentImages.length > 0 ? (
                <div className="image-gallery d-flex justify-content-center">
                  {currentImages.map((img, index) => (
                    <div key={index} className="p-2 border m-1" style={{ height: "100px", width: "100px", borderRadius: "5px" }}>
                      <img
                        src={IMAGE_URL + img.image}
                        alt={`Case Image ${index + 1}`}
                        className="img-fluid mb-3"
                      />
                      <Button 
                        variant="primary"
                        onClick={() => handleDownloadImage(img.image)} 
                      >
                        Download Image
                      </Button>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No images available</p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </div>
  );
}
