import React, { useState } from 'react';
import { error_toaster, success_toaster } from "../../Utils/Toaster";
import { PostApi } from '../../ApiClient/PostApi';
import Loader from '../../Utils/Loader';
import { setLoginStatus } from '../../Utils/AuthCheck';
import { useNavigate } from 'react-router-dom';
import { getAllPlans, homeRoute } from '../../Utils/routes';
import useFetch from '../../ApiClient/GetApi';


const Register = () => {
    const navigate = useNavigate();
    // State to handle form inputs
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [planId, setPlanId] = useState(null);
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const {apiData,loading} = useFetch(getAllPlans);
    console.log(apiData?.data)


    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Handle form submission logic here
        if (password !== confirmPassword) {
            error_toaster("Passwords do not match!");
            return;
        }
        if (!agreeTerms) {
            error_toaster("Please agree the terms and privacy");
            return;
        }
        setLoading(true);
        let response = await PostApi("/user/register", {
            name, email, password, priceId: planId
        });
        if (response.data.status == "1") {
            setLoginStatus("1");
            localStorage.setItem("userId", response?.data?.data?.id);
            localStorage.setItem("name", response?.data?.data?.name);
            localStorage.setItem("role", response?.data?.data?.role);
            localStorage.setItem("priceId", response?.data?.data?.priceId); // Store priceId
            setLoading(false);
    
            success_toaster(response.data.message);
    
            // Use navigate or window.location.href based on the type of URL
            if (response?.data?.data?.url) {
                // Redirect to Stripe URL (no concatenation with register URL)
                window.location.href = response?.data?.data?.url;
            }
            
        }
        else {
            setLoading(false);
            error_toaster(response.data.message);
        }
    };
    
    return (
        <div className="account-page">
            {
               isLoading || loading ? <Loader /> : <div className="main-wrapper">
                    <div className="account-content">
                        <div className="login-wrapper register-wrap bg-img">
                            <div className="login-content">
                                <form onSubmit={handleSubmit}>
                                    <div className="login-userset">
                                        <div className="login-logo logo-normal">
                                            <img src="assets/img/black-logo.png" alt="Logo" />
                                        </div>
                                        <a href="#" className="login-logo logo-white">
                                            <img src="assets/img/black-logo.png" alt="Logo" />
                                        </a>
                                        <div className="login-userheading">
                                            <h3>Register</h3>
                                            <h4>Create New Dreamspos Account</h4>
                                        </div>
                                        <div className="form-login">
                                            <label>Name</label>
                                            <div className="form-addons">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                />
                                                <img src="assets/img/icons/user-icon.svg" alt="User" />
                                            </div>
                                        </div>
                                        <div className="form-login">
                                            <label>Email Address</label>
                                            <div className="form-addons">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                                <img src="assets/img/icons/mail.svg" alt="Email" />
                                            </div>
                                        </div>
                                        <div className="form-login">
                                            <label>Password</label>
                                            <div className="pass-group">
                                                <input
                                                    type="password"
                                                    className="pass-input"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                                <span className="fas toggle-password fa-eye-slash"></span>
                                            </div>
                                        </div>
                                        <div className="form-login">
                                            <label>Confirm Password</label>
                                            <div className="pass-group">
                                                <input
                                                    type="password"
                                                    className="pass-inputs"
                                                    value={confirmPassword}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                />
                                                <span className="fas toggle-passwords fa-eye-slash"></span>
                                            </div>
                                        </div>
                                        <div className="form-login">
                                            <label>Choose your Plan</label>
                                            <div className="pass-group">
                                              <select onChange={(e)=>setPlanId(e.target.value)} className='form-control'>
                                                <option>Select Plan</option>
                                                {
                                                    apiData?.data?.map((plan)=>(
                                                        <option value={plan?.priceId}>{plan?.productName} -${plan.unitAmount}</option>
                                                    ))
                                                }
                                              </select>
                                            </div>
                                        </div>
                                        <div className="form-login authentication-check">
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <div className="custom-control custom-checkbox justify-content-start">
                                                        <div className="custom-control custom-checkbox">
                                                            <label className="checkboxs ps-4 mb-0 pb-0 line-height-1">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={agreeTerms}
                                                                    onChange={(e) => setAgreeTerms(e.target.checked)}
                                                                />
                                                                <span className="checkmarks"></span>
                                                                I agree to the <a href="#" className="hover-a">Terms & Privacy</a>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-login">
                                            <button type="submit" className="btn btn-login">Sign Up</button>
                                        </div>
                                        <div className="signinform">
                                            <h4>Already have an account? <a href="signin.html" className="hover-a">Sign In Instead</a></h4>
                                        </div>
                                        <div className="form-setlogin or-text">
                                            <h4>OR</h4>
                                        </div>
                                        <div className="form-sociallink">
                                            <ul className="d-flex justify-content-center">
                                                <li>
                                                    <a href="javascript:void(0);" className="facebook-logo">
                                                        <img src="assets/img/icons/facebook-logo.svg" alt="Facebook" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <img src="assets/img/icons/google.png" alt="Google" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
                                            <p>Copyright &copy; 2023 DreamsPOS. All rights reserved</p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default Register;
