import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { LoginCheck } from "./AuthCheck";
import { useNavigate } from "react-router-dom";
import { purchasePlan } from "./routes";

export default function UserProtectedRoutes(props) {
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [isPlan, setIsPlan] = useState(null);

  useEffect(() => {
    const role = localStorage.getItem("role");
    const plan = localStorage.getItem("isPlan");
    if (role !== "user") {
      setIsAuthorized(false); // Unauthorized, set state
    }
    setIsPlan(plan)
  }, []);

  LoginCheck(); // Assuming this function has side effects and doesn’t affect rendering directly
  useEffect(()=>{
    if(isPlan == "0"){
      navigate(purchasePlan)
    }
  })

  if (!isAuthorized) {
    return <p>You don't have permission to access this page!</p>;
  }

  const { Component } = props;
  return (
    <>
      <Component />
    </>
  );
}
