import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useFetch from '../../ApiClient/GetApi';
import { caseDetails } from '../../Utils/routes';
import SideBar from '../../layouts/SideBar';
import { IMAGE_URL } from '../../Utils/urls';
import { PostApi } from '../../ApiClient/PostApi';
import { error_toaster, success_toaster } from '../../Utils/Toaster';
import { FaDeleteLeft } from "react-icons/fa6";
import PdfToImg from "pdftoimg-js/browser";

export default function CaseDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([{ id: Date.now(), file: null, name: '' }]);
  const { apiData, reFetch } = useFetch(`${caseDetails}/${location?.state?.id}`);
  const details = apiData?.data?.details;

  const convertPdfToImage = async (file, id) => {
    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const pdfArrayBuffer = e.target.result; // Get the ArrayBuffer of the file
        const images = await PdfToImg(pdfArrayBuffer); // Convert PDF to base64 images

        if (images && images.length > 0) {
          // Process each page or the first page as needed
          const base64Image = images[0]; // Example: Take the first page
          const blob = await fetch(base64Image).then((res) => res.blob());

          setImages((prevImages) =>
            prevImages.map((image) =>
              image.id === id ? { ...image, file: blob, name: '' } : image
            )
          );
          // success_toaster("PDF converted to image successfully!");
        } else {
          error_toaster("No pages found in the PDF.");
        }
      };
      reader.readAsArrayBuffer(file); // Read file as ArrayBuffer
    } catch (error) {
      console.error("Error converting PDF to image:", error);
      error_toaster("Failed to convert PDF to image.");
    }
  };
  // Handle name change for each image
  const handleImageChange = async (id, file) => {
    if (file) {
      if (file.type === "application/pdf") {
        await convertPdfToImage(file, id);
      } else {
        setImages((prevImages) =>
          prevImages.map((image) =>
            image.id === id ? { ...image, file, name: file.name } : image
          )
        );
      }
    }
  };
  const handleImageNameChange = (id, name) => {
    setImages((prevImages) =>
      prevImages.map((image) =>
        image.id === id ? { ...image, name } : image
      )
    );
  };

  // Add new image input field
  const handleAddImage = () => {
    setImages([...images, { id: Date.now(), file: null, name: '' }]);
  };

  // Remove an image input field
  const handleRemoveImage = (id) => {
    setImages(images.filter((image) => image.id !== id));
  };

  const uploadImages = async () => {
    const payload = new FormData();
    const imageNames = [];
    const imagesToUpload = [];

    images.forEach((image) => {
      if (image.file) {
        imagesToUpload.push(image.file); // Add image file to the array
        imageNames.push(image.name || `Image-${image.id}`); // Add image name (default or provided)
      }
    });

    // Append image files
    imagesToUpload.forEach((file) => {
      payload.append('images', file); // Append each image file
    });

    // Append image names
    imageNames.forEach((name) => {
      payload.append('imageNames', name); // Append each image name
    });

    payload.append("caseId", location?.state?.id);

    try {
      setLoading(true);
      const response = await PostApi('/user/updateCaseImages', payload, {
        headers: { 'Content-Type': 'multipart/form-data' }, // Ensure proper headers
      });

      console.log(response);
      if (response.data.status === '1') {
        reFetch();
        success_toaster(response.data.message);
        setLoading(false);

        // Reset images state after successful upload
        setImages([]);  // Clear the images after successful upload

      } else {
        setLoading(false);
        error_toaster(response.data.message || 'Failed to add case.');
      }
    } catch (error) {
      setLoading(false);
      console.error('Error adding case:', error.response || error);
      error_toaster(error.message);
    }
  }


  const deleteImage = async (id) => {
    let response = await PostApi("/user/deleteImage", { id: id });
    if (response.data.status == "1") {
      success_toaster(response.data.message);
      reFetch();
    }
    else {
      error_toaster(response.data.message)
    }
  }
  const deleteEditedImage = async (id) => {
    let response = await PostApi("/user/deleteEditedImage", { id: id });
    if (response.data.status == "1") {
      success_toaster(response.data.message);
      reFetch();
    }
    else {
      error_toaster(response.data.message)
    }
  }

  return (
    <div className="main-wrapper">
      <SideBar />
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="page-header">
              <div className="page-title">
                <h4 style={{ textAlign: 'start' }}>Case Details</h4>
                <h6>View all Case Details</h6>
              </div>
            </div>
          </div>

          {details ? (
            <div className="row">
              {/* Case Details Card */}
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className='my-3 bg-primary'><h5 style={{ padding: "15px 10px", borderRadius: "5px", color: "white" }}>Case Information</h5></div>
                    <div className="row">
                      <div className="col-md-6">
                        <p><strong>Case No:</strong> {details.caseNo}</p>
                        <p><strong>Client Name:</strong> {details.clientName}</p>
                        <p><strong>Phone Number:</strong> {details.phoneNum}</p>
                      </div>
                      <div className="col-md-6">
                        <p><strong>Email:</strong> {details.email}</p>
                        <p><strong>Details:</strong> {details.details}</p>
                        <p><strong>Status:</strong> {details.status ? 'Active' : 'Inactive'}</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              {/* Case Images */}
              {details.caseimages && details.caseimages.length > 0 && (
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <h5 className='py-4'>Case Images</h5>
                      <div className="row">
                        {details.caseimages.map((image) => (
                          <div key={image.id} className="col-md-6">
                            <div className="card" style={{ marginBottom: '15px' }}>
                              <div onClick={() => deleteImage(image.id)} style={{ cursor: "pointer" }}><FaDeleteLeft color='red' size={30} /></div>
                              <div className="card-body text-center">
                                <h6>{image.name}</h6>
                                <img
                                  src={`${IMAGE_URL}${image.image}`}
                                  alt={image.name}
                                  style={{ width: '100%', height: '300px', marginBottom: '10px', objectFit: "contain" }}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {details.editedimages && details.editedimages.length > 0 && (
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <h5 className='py-4'>Edited Images</h5>
                      <div className="row">
                        {details.editedimages.map((image) => (
                          <div key={image.id} className="col-md-6">
                            <div className="card" style={{ marginBottom: '15px' }}>
                              <div onClick={() => deleteEditedImage(image.id)} style={{ cursor: "pointer" }}><FaDeleteLeft color='red' size={30} /></div>
                              <div className="card-body text-center">
                                <h6>{image.name}</h6>
                                <img
                                  src={`${IMAGE_URL}${image.image}`}
                                  alt={image.name}
                                  style={{ width: '100%', height: '300px', marginBottom: '10px', objectFit: "contain" }}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="row mb-4">
                <div className="col-lg-12" style={{ textAlign: "start" }}>
                  <h6 style={{ marginBottom: "8px" }}>Upload Documents</h6>
                  {images.map((image, index) => (
                    <div key={image.id} className="add-choosen mb-2">
                      <div className="d-flex align-items-center">
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={(e) => handleImageChange(image.id, e.target.files[0])}
                          className="form-control me-2"
                        />
                        <input
                          type="text"
                          className="form-control me-2"
                          value={image.name}
                          onChange={(e) => handleImageNameChange(image.id, e.target.value)}
                          placeholder="Document Name"
                        />
                        {index > 0 && (
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => handleRemoveImage(image.id)}
                          >
                            Remove
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                  <button type="button" className="btn btn-primary mt-2" onClick={handleAddImage}>
                    Add More
                  </button>
                </div>
                <div className="mt-5">
                  <button onClick={() => uploadImages()} type="submit" className="btn btn-primary me-3">Save</button>
                  <button type="button" className="btn btn-secondary">Cancel</button>
                </div>
              </div>
            </div>

          ) : (
            <p>Loading case details...</p>
          )}
        </div>
      </div>
    </div>
  );
}
