export const signinRoute = "/sign-in";
export const registerRoute = "/register";

export const login = "/user/login"

//ADMIN ROUTES
export const homeRoute = "/admin";
export const plansRoute = "/admin/all-plans";
export const usersRoutes = "/admin/all-users";

//USER ROUTES
export const userHomeRoute = "/";
export const createCasesRoute = "/create-case";
export const allCasesRoute = "/all-cases";
export const purchasePlan = "/purchase-plan";
export const paymentSuccessRoute = "/payment-success";
export const userCaseDetails = "/case-details";
export const editor = "/editor";
export const editCaseRoute = "/edit-case";


//admin apis routes
export const getAllSubscriptionPlans = "/admin/getAllSubscriptionPlans"
export const getAllUsers = "/admin/getAllUsers"
export const addPlan = "/admin/addPlan"
export const deletePlanRoute = "/admin/deletePlan"

//user apis routes
export const usergetAllCases = "/user/getAllCases"
export const subscribePlanRoute = "/user/subscribePlan"
export const getAllPlans = "/user/getAllPlans"
export const confirmSubscriptionRoute = "/user/confirmSubscription"
export const caseDetails = "/user/caseDetails"
export const userCaseImages = "/user/userCaseImages"
export const userEditCase = "/user/userEditCase"
export const deleteCase = "/user/deleteCase"
