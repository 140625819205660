import React, { useEffect } from 'react'
import { allCasesRoute, createCasesRoute, editor, homeRoute, plansRoute, signinRoute, userHomeRoute, usersRoutes } from '../Utils/routes'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import feather from 'feather-icons';

export default function SideBar() {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        // Re-render Feather icons
        feather.replace();
    }, [location]); // Trigger on location change

    const logout = async () => {
        localStorage.removeItem("userId");
        localStorage.removeItem("loginstatus");
        navigate(signinRoute)
    }
    return (
        <div>
            <div className="header">
                <div className="header-left active">
                    <a href="index.html" className="logo logo-normal">
                        <img src="assets/img/black-logo.png" alt="" />
                    </a>
                    <a href="index.html" className="logo logo-white">
                        <img src="assets/img/black-logo.png" alt="" />
                    </a>
                    <a href="index.html" className="logo-small">
                        <img src="assets/img/black-logo.png" alt="" />
                    </a>
                    <a id="toggle_btn" href="javascript:void(0);">
                        <i data-feather="chevrons-left" className="feather-16"></i>
                    </a>
                </div>
                <a id="mobile_btn" className="mobile_btn" href="#sidebar">
                    <span className="bar-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </a>

                <ul className="nav user-menu">
                    <div className="nav-item nav-searchinputs d-flex justify-content-end">


                        <li class="nav-item dropdown has-arrow main-drop">
                            <a href="javascript:void(0);" class="dropdown-toggle nav-link userset" data-bs-toggle="dropdown">
                                <span class="user-info">
                                    <span class="user-letter">
                                        <img src="assets/img/profiles/avator1.jpg" alt="" class="img-fluid" />
                                    </span>
                                    <span class="user-detail">
                                        <span class="user-name">{localStorage.getItem("name")}</span>
                                        <span class="user-role">{localStorage.getItem("role")}</span>
                                    </span>
                                </span>
                            </a>
                            <div class="dropdown-menu menu-drop-user">
                                <div class="profilename">
                                    <div class="profileset">
                                        <span class="user-img"><img src="assets/img/profiles/avator1.jpg" alt="" />
                                            <span class="status online"></span></span>
                                        <div class="profilesets">
                                            <h6>{localStorage.getItem("name")}</h6>
                                            <h5>{localStorage.getItem("role")}</h5>
                                        </div>
                                    </div>
                                    <hr class="m-0" />
                                    <a class="dropdown-item" href="profile.html"> <i class="me-2" data-feather="user"></i> My
                                        Profile</a>

                                    <hr class="m-0" />
                                    <a class="dropdown-item logout pb-0" onClick={() => logout()}><img
                                        src="assets/img/icons/log-out.svg" class="me-2" alt="img" />Logout</a>
                                </div>
                            </div>
                        </li>
                    </div>
                </ul>
                <div class="dropdown mobile-user-menu">
                    <a href="javascript:void(0);" class="nav-link dropdown-toggle" data-bs-toggle="dropdown"
                        aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                    <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="profile.html">My Profile</a>
                        <a class="dropdown-item" href="signin.html">Logout</a>
                    </div>
                </div>
                <div class="dropdown-menu menu-drop-user">
                    <div class="profilename">
                        <div class="profileset">
                            <span class="user-img"><img src="assets/img/profiles/avator1.jpg" alt="" />
                                <span class="status online"></span></span>
                            <div class="profilesets">
                                <h6>John Smilga</h6>
                                <h5>Super Admin</h5>
                            </div>
                        </div>
                        <hr class="m-0" />
                        <a class="dropdown-item" href="profile.html"> <i class="me-2" data-feather="user"></i> My
                            Profile</a>

                        <hr class="m-0" />
                        <a class="dropdown-item logout pb-0" href="signin.html"><img
                            src="assets/img/icons/log-out.svg" class="me-2" alt="img" />Logout</a>
                    </div>
                </div>
            </div>
            <div className="sidebar" id="sidebar">
                <div className="sidebar-inner slimscroll">
                    <div id="sidebar-menu" className="sidebar-menu" style={{ width: "120%" }}>
                        <ul>
                            {
                                localStorage.getItem("role") == "admin" ?
                                    // ADMIN SIDEBAR 
                                    <li className="submenu-open">
                                        <ul>
                                            <li className={location?.pathname == homeRoute ? "active" : ""}><Link to={homeRoute}><i data-feather="grid"></i><span>Dashboarddd</span></Link></li>
                                            <li className={location?.pathname == usersRoutes ? 'active' : ''}><Link to={usersRoutes}><i
                                                data-feather="users"></i><span>Users</span></Link></li>
                                            <li className={location?.pathname == plansRoute ? 'active' : ''}><Link to={plansRoute}><i data-feather="shuffle"></i><span>Plans</span></Link></li>


                                        </ul>
                                    </li> :

                                    // USER SIDEBAR
                                    <li className="submenu-open">
                                        <ul>
                                            <li className={location?.pathname == userHomeRoute ? "active" : ""}><Link to={userHomeRoute}><i data-feather="grid"></i><span>Dashboard</span></Link></li>
                                            <li className={location?.pathname == createCasesRoute ? "active" : ""}><Link to={createCasesRoute}><i data-feather="grid"></i><span>Create Case</span></Link></li>
                                            <li className={location?.pathname == editor ? "active" : ""}><Link to={editor}><i data-feather="grid"></i><span>Editor</span></Link></li>



                                        </ul>
                                    </li>
                            }

                        </ul>
                    </div>
                </div>
                <div className="sidebar collapsed-sidebar" id="collapsed-sidebar">
                    <div className="sidebar-inner slimscroll">
                        <div id="sidebar-menu-2" className="sidebar-menu sidebar-menu-three">
                            <aside id="aside" className="ui-aside">
                                <ul className="tab nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a className="tablinks nav-link active" href="#home" id="home-tab" data-bs-toggle="tab"
                                            data-bs-target="#home" role="tab" aria-selected="true">
                                            <img src="assets/img/icons/menu-icon.svg" alt="" />
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="tablinks nav-link" href="#messages" id="messages-tab" data-bs-toggle="tab"
                                            data-bs-target="#product" role="tab" aria-selected="false">
                                            <img src="assets/img/icons/product.svg" alt="" />
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="tablinks nav-link" href="#profile" id="profile-tab" data-bs-toggle="tab"
                                            data-bs-target="#sales" role="tab" aria-selected="false">
                                            <img src="assets/img/icons/sales1.svg" alt="" />
                                        </a>
                                    </li>

                                    <li className="nav-item" role="presentation">
                                        <a className="tablinks nav-link" href="#report" id="report-tab" data-bs-toggle="tab"
                                            data-bs-target="#purchase" role="tab" aria-selected="true">
                                            <img src="assets/img/icons/purchase1.svg" alt="" />
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="tablinks nav-link" href="#set" id="set-tab" data-bs-toggle="tab"
                                            data-bs-target="#user" role="tab" aria-selected="true">
                                            <img src="assets/img/icons/users1.svg" alt="" />
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="tablinks nav-link" href="#set2" id="set-tab2" data-bs-toggle="tab"
                                            data-bs-target="#employee" role="tab" aria-selected="true">
                                            <img src="assets/img/icons/calendars.svg" alt="" />
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="tablinks nav-link" href="#set3" id="set-tab3" data-bs-toggle="tab"
                                            data-bs-target="#report" role="tab" aria-selected="true">
                                            <img src="assets/img/icons/printer.svg" alt="" />
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="tablinks nav-link" href="#set4" id="set-tab4" data-bs-toggle="tab"
                                            data-bs-target="#document" role="tab" aria-selected="true">
                                            <i data-feather="file-minus"></i>
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="tablinks nav-link" href="#set5" id="set-tab6" data-bs-toggle="tab"
                                            data-bs-target="#permission" role="tab" aria-selected="true">
                                            <i data-feather="user"></i>
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="tablinks nav-link" href="#set6" id="set-tab5" data-bs-toggle="tab"
                                            data-bs-target="#settings" role="tab" aria-selected="true">
                                            <i data-feather="settings"></i>
                                        </a>
                                    </li>
                                </ul>
                            </aside>
                            <div className="tab-content tab-content-four pt-2">
                                <ul className="tab-pane active" id="home" aria-labelledby="home-tab">
                                    <li className="submenu-open">
                                        {
                                            localStorage.getItem("admin") == "admin" ?
                                                // ADMIN SIDEBAR 
                                                <ul>
                                                    <li className={location?.pathname == homeRoute ? "active" : ""}><Link to={homeRoute}><i data-feather="grid"></i><span>Dashboard</span></Link></li>
                                                    <li ><Link to={usersRoutes}><i
                                                        data-feather="users"></i><span>Users</span></Link></li>
                                                    <li><Link to={plansRoute}><i data-feather="shuffle"></i><span>Plans</span></Link></li>
                                                </ul> :
                                                // USER SIDEBAR 
                                                <ul>
                                                    <li className={location?.pathname == userHomeRoute ? "active" : ""}><Link to={userHomeRoute}><i data-feather="grid"></i><span>Dashboard</span></Link></li>
                                                    <li className={location?.pathname == createCasesRoute ? "active" : ""}><Link to={createCasesRoute}><i
                                                        data-feather="users"></i><span>Create Case</span></Link></li>

                                                    <li className={location?.pathname == editor ? "active" : ""}><Link to={editor}><i data-feather="shuffle"></i><span>Editor</span></Link></li>
                                                </ul>
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sidebar horizontal-sidebar">
                    <div id="sidebar-menu-3" className="sidebar-menu">
                        <ul className="nav">
                            <li className="submenu-open">
                                <ul>
                                    <li className={location?.pathname == homeRoute ? "active" : ""}><Link to={homeRoute}><i data-feather="grid"></i><span>Dashboard</span></Link></li>
                                    <li ><a href="user.html"><i
                                        data-feather="users"></i><span>Users</span></a></li>
                                    <li><Link to={plansRoute}><i data-feather="shuffle"></i><span>Plans</span></Link></li>


                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    )
}
