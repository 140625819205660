import React, { useEffect, useState } from 'react';
import { success_toaster, error_toaster } from '../../Utils/Toaster';
import Loader from "../../Utils/Loader";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { allCasesRoute, userEditCase, userHomeRoute } from '../../Utils/routes';
import SideBar from '../../layouts/SideBar';
import useFetch from '../../ApiClient/GetApi';
import { PostApi } from '../../ApiClient/PostApi';


export default function EditCase() {
    const navigate = useNavigate();
    const location = useLocation();
   const [caseId,setCaseId] = useState(null)
    const [images, setImages] = useState([]); // Manage dynamic image uploads with name
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        caseNo: '',
        clientName: '',
        phone: '',
        email: '',
        detail: '',
    });

    useEffect(()=>{
        setCaseId(location?.state?.id);
    },[]);

    // Fetch case data when component mounts
    const { apiData, loading: fetchLoading } = useFetch(`${userEditCase}/${caseId}`); // Assuming userEditCase is the endpoint for fetching a case by ID

    useEffect(() => {
        if (apiData?.data?.data) {
            // Pre-fill form data with the fetched case details
            const { caseNo, clientName, phoneNum, email, details, caseimages } = apiData.data.data;
            setFormData({ caseNo, clientName, phone: phoneNum, email, detail: details });

            // Pre-fill the images state with existing image data (if any)
            if (caseimages?.length) {
                setImages(caseimages.map((img) => ({
                    id: img.id,
                    name: img.name,
                    imageUrl: img.image, // Storing the image URL for display purposes
                })));
            }
        }
    }, [apiData]);

    // Handle file change for dynamic image uploads
    const handleImageChange = (id, file) => {
        setImages((prevImages) =>
            prevImages.map((image) =>
                image.id === id ? { ...image, file } : image
            )
        );
    };

    // Handle name change for each image
    const handleImageNameChange = (id, name) => {
        setImages((prevImages) =>
            prevImages.map((image) =>
                image.id === id ? { ...image, name } : image
            )
        );
    };

    // Add new image input field
    const handleAddImage = () => {
        setImages([...images, { id: Date.now(), file: null, name: '', imageUrl: '' }]);
    };

    // Remove an image input field
    const handleRemoveImage = (id) => {
        setImages(images.filter((image) => image.id !== id));
    };

    // Handle form field changes
    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form fields
        if (!formData.caseNo || !formData.clientName || !formData.phone || !formData.email) {
            error_toaster('Please fill all required fields.');
            return;
        }

        // Prepare form data for submission
        const payload = new FormData();
        payload.append('caseNo', formData.caseNo);
        payload.append('id', caseId);
        payload.append('clientName', formData.clientName);
        payload.append('phone', formData.phone);
        payload.append('email', formData.email);
        payload.append('detail', formData.detail);
        payload.append('userId', localStorage.getItem('userId'));

        // Prepare image files and names for upload
        const imageNames = [];
        const imagesToUpload = [];

        images.forEach((image) => {
            if (image.file) {
                imagesToUpload.push(image.file); // Add image file to the array
                imageNames.push(image.name || `Image-${image.id}`); // Add image name (default or provided)
            }
        });

        // Append image files
        imagesToUpload.forEach((file) => {
            payload.append('images', file); // Append each image file
        });

        // Append image names
        imageNames.forEach((name) => {
            payload.append('imageNames', name); // Append each image name
        });

        try {
            setLoading(true);
            // Call the API to update the case
            const response = await PostApi(`/user/updateCase`, payload, {
                headers: { 'Content-Type': 'multipart/form-data' }, // Ensure proper headers
            });

            if (response.data.status === '1') {
                success_toaster(response.data.message);
                setLoading(false);
                navigate(userHomeRoute); // Redirect back to the cases list page after successful update
            } else {
                setLoading(false);
                error_toaster(response.data.message || 'Failed to update case.');
            }
        } catch (error) {
            setLoading(false);
            console.error('Error updating case:', error.response || error);
            error_toaster(error.message);
        }
    };

    return (
        <div>
            {loading || fetchLoading ? (
                <Loader />
            ) : (
                <div>
                    <SideBar />
                    <div className="page-wrapper">
                        <div className="content">
                            <div className="row">
                                <div className="page-header">
                                    <div className="page-title">
                                        <h4>Edit Case</h4>
                                        <h6>Edit the case details here</h6>
                                    </div>
                                    <div className="page-btn">
                                        <button onClick={() => navigate(allCasesRoute)} className="btn btn-added color">
                                            Back to Cases
                                        </button>
                                    </div>
                                </div>
                                <form className="mb-5 mt-5" onSubmit={handleSubmit}>
                                    <div className="row mb-4">
                                        <div className="col-md-6">
                                            <label htmlFor="caseNo" className="form-label">Case No</label>
                                            <input
                                                type="text"
                                                min="1"
                                                className="form-control"
                                                id="caseNo"
                                                value={formData.caseNo}
                                                onChange={handleInputChange}
                                                placeholder="Enter Case Number"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="clientName" className="form-label">Client Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="clientName"
                                                value={formData.clientName}
                                                onChange={handleInputChange}
                                                placeholder="Enter Client Name"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <div className="col-md-6">
                                            <label htmlFor="phone" className="form-label">Phone No</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="phone"
                                                value={formData.phone}
                                                onChange={handleInputChange}
                                                placeholder="Enter Phone No"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                placeholder="smith@gmail.com"
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <div className="col-md-12">
                                            <label htmlFor="detail" className="form-label">Case Detail</label>
                                            <textarea
                                                className="form-control"
                                                id="detail"
                                                rows="4"
                                                value={formData.detail}
                                                onChange={handleInputChange}
                                                placeholder="Enter Client Detail here..."
                                            ></textarea>
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <div className="col-lg-12" style={{ textAlign: "start" }}>
                                            <h6 style={{ marginBottom: "8px" }}>Upload Documents</h6>
                                            {images.map((image, index) => (
                                                <div key={image.id} className="add-choosen mb-2">
                                                    <div className="d-flex align-items-center">
                                                        <input
                                                            type="file"
                                                            accept="image/png, image/jpeg"
                                                            onChange={(e) => handleImageChange(image.id, e.target.files[0])}
                                                            className="form-control me-2"
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control me-2"
                                                            value={image.name}
                                                            onChange={(e) => handleImageNameChange(image.id, e.target.value)}
                                                            placeholder="Document Name"
                                                        />
                                                        {index > 0 && (
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger"
                                                                onClick={() => handleRemoveImage(image.id)}
                                                            >
                                                                Remove
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                            <button type="button" className="btn btn-primary mt-2" onClick={handleAddImage}>
                                                Add More
                                            </button>
                                        </div>
                                    </div>

                                    <div className="mt-5">
                                        <button type="submit" className="btn btn-primary me-3">Save</button>
                                        <button type="button" className="btn btn-secondary" onClick={() => navigate(allCasesRoute)}>
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
