import React, { useState } from 'react';
import { error_toaster, success_toaster } from "../Utils/Toaster";
import { homeRoute, login, purchasePlan, registerRoute, userHomeRoute } from '../Utils/routes';
import { PostApi } from '../ApiClient/PostApi';
import Loader from '../Utils/Loader';
import { Link, useNavigate } from 'react-router-dom';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevents the default form submission
        if (!email || !password) {
            success_toaster('Email and Password are required.');
            return;
        }
        setLoading(true)
        let response = await PostApi(login, { email, password });
        console.log(response)
        if (response.data.status == "1") {
            console.log(response)
           
                
                setLoading(false);
                localStorage.setItem("loginstatus","1");
                localStorage.setItem("userId",response.data.data.id);
                localStorage.setItem("accessToken",response.data.data.accessToken);
                localStorage.setItem("role",response.data.data.role);
                localStorage.setItem("name",response.data.data.name);
                success_toaster(response.data.message);
                if(response.data.data.role == "admin"){
                    navigate(homeRoute)
                }
                else{
                    if (response.data.data.isPlan) {
                        localStorage.setItem("isPlan","1");
                        navigate(userHomeRoute)
                    }
                    else{
                        localStorage.setItem("isPlan","0");
                        navigate(purchasePlan)
                    }
                    
                }
                

               
        }
        else {
            error_toaster(response.data.message);
            setLoading(false);
        }
    };

    return (
        <div>
            {
                loading ? <Loader /> : <div className="main-wrapper">
                    <div className="account-content">
                        <div className="login-wrapper bg-img">
                            <div className="login-content">
                                <form onSubmit={handleSubmit}>
                                    <div className="login-userset">
                                        <div className="login-logo logo-normal">
                                            <img src="assets/img/black-logo.png" alt="" />
                                        </div>
                                        <a href="index.html" className="login-logo logo-white">
                                            <img src="assets/img/black-logo.png" alt="" />
                                        </a>
                                        <div className="login-userheading">
                                            <h3>Sign In</h3>
                                            <h4>Access the Dreamspos panel using your email and passcode.</h4>
                                        </div>
                                        <div className="form-login mb-3">
                                            <label className="form-label">Email Address</label>
                                            <div className="form-addons">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    required
                                                />
                                                <img src="assets/img/icons/mail.svg" alt="img" />
                                            </div>
                                        </div>
                                        <div className="form-login mb-3">
                                            <label className="form-label">Password</label>
                                            <div className="pass-group">
                                                <input
                                                    type="password"
                                                    className="pass-input form-control"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    required
                                                />
                                                <span className="fas toggle-password fa-eye-slash"></span>
                                            </div>
                                        </div>
                                        <div className="form-login authentication-check">
                                            <div className="row">
                                                <div className="col-12 d-flex align-items-center justify-content-between">
                                                    <div className="custom-control custom-checkbox">
                                                        <label className="checkboxs ps-4 mb-0 pb-0 line-height-1">
                                                            <input type="checkbox" className="form-control" />
                                                            <span className="checkmarks"></span>Remember me
                                                        </label>
                                                    </div>
                                                    <div className="text-end">
                                                        <a className="forgot-link" href="forgot-password.html">Forgot Password?</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-login">
                                            <button type="submit" className="btn btn-login">Sign In</button>
                                        </div>
                                        <div className="signinform">
                                            <h4>New on our platform?<Link to={registerRoute} className="hover-a"> Create an account</Link></h4>
                                        </div>
                                        <div className="form-setlogin or-text">
                                            <h4>OR</h4>
                                        </div>
                                        <div className="form-sociallink">
                                            <ul className="d-flex justify-content-center">
                                                <li>
                                                    <a href="javascript:void(0);" className="facebook-logo">
                                                        <img src="assets/img/icons/facebook-logo.svg" alt="Facebook" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);">
                                                        <img src="assets/img/icons/google.png" alt="Google" />
                                                    </a>
                                                </li>
                                            </ul>
                                            <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
                                                <p>Copyright &copy; 2023 DreamsPOS. All rights reserved</p>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
    );
}
